<template>
  <div class="main">
    <el-row>
      <el-col :span="24">
        <el-row :gutter="40">
          <el-col :span="16">
            <div class="content kefan">
              <!-- 确认所在园区  -->
              <div class="range" id="glhtrk">
                <h4 class="title">管理后台入口</h4>
                <el-divider></el-divider>
                <p class="enterance">
                  <ul>
                    <li>1.智慧湾：https://admin.kefan.com/</li>
                    <li>2.智慧坊：https://zhf.wis-p.cn/</li>
                    <li>3.交运智慧湾：https://jyzhw.jy-wisdombay.cn/</li>
                    <li>4.智慧七立方：https://wis777.kefan.com/</li>
                    <li>5.科房旗下园区：https://kf.kefan.com/</li>
                  </ul>
                </p>
              </div>
              <!-- 水电费账单 -->
              <div class="range" id="sdfzd">
                <h4 class="title">水电费账单</h4>
                <el-divider></el-divider>
                <p class="describe">
                  1.点击账单管理下的蓝色方框“水电费账单确认”（如图2.23）
                </p>
                <el-image 
                style="width:100%"
                :src="img.aurl[0]"
                :preview-src-list="img.aurl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图2.23</p>

                <p class="describe">
                  2.上方白色方框内可对水电费账单进行筛选（如图2.24）客户名称、导入批号、账单金额、账单周期
                </p>
                <el-image 
                style="width:100%"
                :src="img.burl[0]"
                :preview-src-list="img.burl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图2.24</p>

                <p class="describe">
                  3.点击白色方框下方的“表格下载”（如图2.25）即可下载当前园区水电费信息（excel表格）
                </p>
                <el-image 
                style="width:100%"
                :src="img.curl[0]"
                :preview-src-list="img.curl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图2.25</p>

                <p class="describe">
                  4.点击“表格下载”右侧“数据导入”（如图2.26）
                </p>
                <el-image 
                style="width:100%"
                :src="img.durl[0]"
                :preview-src-list="img.durl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图2.26</p>

                <p class="describe">
                  5.跳出水电费数据导入框（如图2.27）填入账单周期、收款账号、最迟付款日期，上传数据文件后点击保存即可完成导入
                </p>
                <el-image 
                style="width:100%"
                :src="img.eurl[0]"
                :preview-src-list="img.eurl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图2.27</p>

                <p class="describe">
                  6.若表格导入成功则会出现导入成功方框（如图2.28）
                </p>
                <el-image 
                style="width:100%"
                :src="img.furl[0]"
                :preview-src-list="img.furl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图2.28</p>

                <p class="describe">
                  7.若表格模板中的合同id或者公司名称和系统中的不匹配则会出现导入失败的方框（如图2.29）
                </p>
                <el-image 
                style="width:100%"
                :src="img.gurl[0]"
                :preview-src-list="img.gurl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图2.29</p>

                 <p class="describe">
                  8.红色方框画出三个蓝色按钮（如图2.30）可对选中的水电费账单进行“批量删除”、“账单确认”和“更换收款账号”
                </p>
                <el-image 
                style="width:100%"
                :src="img.hurl[0]"
                :preview-src-list="img.hurl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图2.30</p>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="col kefan">
              <el-timeline>
                  <ul>
                    <li v-for="(activity, index) in lists" :key="index" >
                      <el-timeline-item
                      :icon="activity.icon"
                      :type="activity.type"
                      :color="activity.color"
                      :size="activity.size"
                      :timestamp="activity.timestamp">
                      <a :href="activity.target" @click="tolink(index)" :class="link==index?'islink':''">{{activity.name}}</a>
                    </el-timeline-item>
                    </li>
                    <li><el-progress :text-inside="true" :stroke-width="20" :percentage="percentage" color="#4fc08d"></el-progress></li>
                  </ul>
                  
                </el-timeline>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      percentage: 0,
      lists: [
        {
          name: "管理后台入口",
          target: "#glhtrk",
        },
        {
          name: "水电费账单",
          target: "#sdfzd",
        },
      ],
      link: 0,
      img: {
        aurl: [this.$img + "sdfgl/2.23.png"],
        burl: [this.$img + "sdfgl/2.24.png"],
        curl: [this.$img + "sdfgl/2.25.png"],
        durl: [this.$img + "sdfgl/2.26.png"],
        eurl: [this.$img + "sdfgl/2.27.png"],
        furl: [this.$img + "sdfgl/2.28.png"],
        gurl: [this.$img + "sdfgl/2.29.png"],
        hurl: [this.$img + "sdfgl/2.30.png"],
      },
    };
  },
  mounted() {
    window.addEventListener("scroll", this.getscroll);
  },
  methods: {
    tolink(index) {
      this.link = index;
    },
    // 获取滚动百分比
    getscroll() {
      // 页面总高
      var totalH = document.documentElement.scrollHeight;
      // 可视高
      var clientH = document.documentElement.clientHeight;
      // 计算有效高
      var validH = totalH - clientH;

      // 滚动条卷去高度
      var scrollH = document.documentElement.scrollTop;

      // 百分比
      var result = Math.round(((scrollH / validH) * 10000) / 100, 2);
      this.percentage = result;
      // console.log("totalH:" + totalH);
      // console.log("clientH:" + clientH);
      // console.log("scrollH:" + scrollH);
      if (result < 8) {
        this.tolink(0);
      } else {
        this.tolink(1);
      }
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.getscroll);
  },
};
</script>

<style scoped>
.describe {
  font-size: 14px;
  color: #5e6d82;
}
.el-divider--horizontal {
  width: 80px;
  margin: 10px 0 15px 0;
  height: 3px;
  background-color: #4fc08d;
  box-shadow: 0px 3px 7px #4fc08d;
}
.range {
  margin: 0 0 10% 0;
}
.content p {
  width: 95%;
  word-break: break-all;
  word-wrap: break-word;
  padding: 10px 0;
}
.content {
  padding: 20px 40px;
}
:target {
  padding-top: 80px;
  margin-top: -80px;
}
.col {
  position: fixed;
  top: 80px;
  width: 300px;
  /* height: 30%; */
}
.col a {
  color: #5e6d82;
}
.col a:hover {
  color: #4fc08d;
}
.col a:after {
  color: #4fc08d;
  font-weight: 700;
}
.col .islink {
  color: #4fc08d;
  font-weight: 700;
}
.enterance {
  background: #5e6d82;
  color: aliceblue;
  border-radius: 8px;
  font-size: 14px;
}
.enterance li {
  padding: 5px 10px;
}
.describe::first-letter {
  font-size: 20px;
  font-weight: 700;
  text-shadow: 3px 2px 2px #4fc08d;
}
</style>